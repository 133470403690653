
import { defineComponent } from 'vue';
import RichText from '@/components/blocks/core/RichText.vue'
import { Content } from '@/types/graphql.sdk'

export default defineComponent({
    components: { RichText },
    props: {
        content: Object as () => Content
    }
})
