import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichText = _resolveComponent("RichText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Array.isArray(_ctx.content.parts))
      ? (_openBlock(), _createBlock(_component_RichText, {
          key: 0,
          parts: _ctx.content.parts
        }, null, 8, ["parts"]))
      : _createCommentVNode("", true)
  ]))
}